






















import { Component, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-custom-investor-goal-card-view-model';

@Component({
  name: 'AddCustomInvestorGoalCard',
  components: {
    AddCustomInvestorGoalModalInit: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalModalInit.vue'),
  },
})
export default class AddCustomInvestorGoalCard extends Vue {
  add_custom_goal_card_view_model = Vue.observable(new AddCustomInvestorGoalCardViewModel());
}
